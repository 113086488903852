<template>
	<div class="page">
		<el-form  label-width="100px">
			
			<div class="big_tit">客户及线路</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="客户和线路" style="width:1000px">
					<el-cascader class="el_inner_width" @change="shipper_bl_change" v-model="shipper_bl.rel" placeholder="请选择" :options="shipper_bl.data" filterable></el-cascader>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="名称/距离" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.cargo_name+' / '+shipper_bl.distance+'公里'" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.case_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地详情" style="width:1000px">
					<el-input class="el_inner_width" v-model="shipper_bl.aim_addr.total_text" disabled clearable></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">重量体积</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物重量(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="cargo_weight.num" :placeholder="cargo_weight.is_need_weight_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="cargo_volume.num" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">司机/车辆</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机电话">
					<el-input class="el_inner_width" type="number" v-model="driver.tel" placeholder="必填,请输入司机电话" clearable>
						<el-button @click="driver_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机信息" style="width:588px">
					<el-input class="el_inner_width" v-model="driver.name" :disabled="true" clearable>
						<el-button @click="driver_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货车牌号">
					<el-input class="el_inner_width" v-model="truck.truck_plate_num" placeholder="必填,请输入车牌号" clearable>
						<el-button @click="truck_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车辆信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck.truck_info_str" :disabled="true" clearable>
						<el-button @click="truck_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">时间信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车时间" style="width:388px">
					<el-date-picker type="datetime" class="el_inner_width" v-model="form.start_time" placeholder="非必填, 如果已发车需要填此项" clearable></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货时间" style="width:588px">
					<el-date-picker  type="datetime" class="el_inner_width" v-model="form.arrived_time" placeholder="非必填, 如果已到货需要填此项" clearable></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit">运费信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游运费" style="width:388px">
					<el-input type="number" class="el_inner_width" v-model="form.freight_total" placeholder="必填, 单位(元)" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">其他信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" style="width:976px" label="运单备注">
					<el-input v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>


			<div style="margin:30px 0 0 0;text-align:center">
				<el-button type="primary" @click="sub">提交订单</el-button>
				<el-button type="danger" @click="clear">清空数据</el-button>
			</div>
		</el-form>
	</div>
</template>
<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	export default {
		data() {
			return {

				//表单
				form: {
					cargo_type:'普货',//货物性质(默认)
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					freight_total:'',//运费
					out_trade_num:'',//上游运单编号
					group1_num:'',//组编号1(用户自定义打组)
					mark:'',//备注
				},

				//货主线路列表
				shipper_bl:{
					rel:null,//选择结果
					data:[],//联动主数据
					original_data:{},//原始数据
					cargo_name:"",//货物名称
					settlement_type:'',//结算方式(1:按吨数,2:按方数,3:按趟)
					is_need_return:'',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					case_addr:{
						prov:'',
						city:'',
						county:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					},
					aim_addr:{
						prov:'',
						city:'',
						county:'',
						addr:'',
						lat:0,
						lng:0,
						total_text:''
					}
				},

				//货物重量
				cargo_weight:{
					num:'',//数值
					is_need_weight:'',//是否必填
					is_need_weight_text:'',//是否必填的placeholder
				},

				//货物体积
				cargo_volume:{
					num:'',//数值
					is_need_volume:'',//是否必填
					is_need_volume_text:'',//是否必填的placeholder
				},

				//司机
				driver:{
					tel:'',//司机电话
					name:'',//司机姓名
					user_num:'',//司机用户编号
				},

				//车辆
				truck:{
					truck_plate_num:'',//车牌号
					truck_info_str:'',//车辆信息字符串
					truck_plate_num_lock:'',//锁定车牌号
				},

				//城市联动数据
				cities:regionData,
			}
		},
		created() {

			//获取参数
			this.get_shipper_bl();
		},
		methods: {

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'tord_act_add_by_admin',
					cargo_type:'普货'
				};

				if(!this.shipper_bl.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else{
					post_data.shipper_num=this.shipper_bl.rel[0]
					post_data.cargo_name=this.shipper_bl.cargo_name.trim()
				}

				//货物重量
				if(this.cargo_weight.is_need_weight==1){//必填
					if(!(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填吨数, 请输入货物吨数'
						});
						return;
					}else post_data.cargo_weight=this.cargo_weight.num
				}else if(this.cargo_weight.is_need_weight==2){//非必填
					if(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0){
						post_data.cargo_weight=this.cargo_weight.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				//货物体积
				if(this.cargo_volume.is_need_volume==1){//必填
					if(!(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填体积, 请输入货物体积'
						});
						return;
					}else post_data.cargo_volume=this.cargo_volume.num
				}else if(this.cargo_volume.is_need_volume==2){//非必填
					if(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0){
						post_data.cargo_volume=this.cargo_volume.num
					}
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请选择路线'
					});
					return;
				}

				if(!this.shipper_bl.case_addr.prov.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货省份'
					});
					return;
				}else post_data.case_prov=this.shipper_bl.case_addr.prov.trim()

				if(!this.shipper_bl.case_addr.city.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货城市'
					});
					return;
				}else post_data.case_city=this.shipper_bl.case_addr.city.trim()

				if(!this.shipper_bl.case_addr.county.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货县区'
					});
					return;
				}else post_data.case_county=this.shipper_bl.case_addr.county.trim()

				if(!this.shipper_bl.case_addr.addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少发货详细地址'
					});
					return;
				}else post_data.case_addr=this.shipper_bl.case_addr.addr.trim()

				if(this.$my.check.is_lat(this.shipper_bl.case_addr.lat)){//发货地纬度
					post_data.case_lat=this.shipper_bl.case_addr.lat
				}

				if(this.$my.check.is_lng(this.shipper_bl.case_addr.lng)){//发货地经度
					post_data.case_lng=this.shipper_bl.case_addr.lng
				}

				if(!this.shipper_bl.aim_addr.prov.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货省份'
					});
					return;
				}else post_data.aim_prov=this.shipper_bl.aim_addr.prov.trim()

				if(!this.shipper_bl.aim_addr.city.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货城市'
					});
					return;
				}else post_data.aim_city=this.shipper_bl.aim_addr.city.trim()

				if(!this.shipper_bl.aim_addr.county.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货县区'
					});
					return;
				}else post_data.aim_county=this.shipper_bl.aim_addr.county.trim()

				if(!this.shipper_bl.aim_addr.addr.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少到货详细地址'
					});
					return;
				}else post_data.aim_addr=this.shipper_bl.aim_addr.addr.trim()

				if(this.$my.check.is_lat(this.shipper_bl.aim_addr.lat)){//到货地纬度
					post_data.aim_lat=this.shipper_bl.aim_addr.lat
				}

				if(this.$my.check.is_lng(this.shipper_bl.aim_addr.lng)){//到货地经度
					post_data.aim_lng=this.shipper_bl.aim_addr.lng
				}

				//结算方式(1:按吨数,2:按方数,3:按趟)
				post_data.settlement_type=this.shipper_bl.settlement_type

				//是否回程(1:需要,2:不需要)
				post_data.is_need_return=this.shipper_bl.is_need_return

				//运输距离
				post_data.distance=this.shipper_bl.distance

				if(this.form.out_trade_num.trim()){//上游运单编号
					post_data.out_trade_num=this.form.out_trade_num.trim()
				}

				if(this.form.group1_num.trim()){//组编号1(用户自定义打组)
					post_data.group1_num=this.form.group1_num.trim()
				}

				if(this.form.mark.trim()){//备注
					post_data.mark=this.form.mark.trim()
				}

				if(!this.driver.user_num){//司机
					this.$my.other.msg({
						type:"warning",
						str:'缺少司机信息'
					});
					return;
				}else{
					post_data.truck_owner_num=this.driver.user_num
					post_data.driver_num=this.driver.user_num
				}

				if(!this.truck.truck_plate_num_lock){//货车
					this.$my.other.msg({
						type:"warning",
						str:'缺少货车信息'
					});
					return;
				}else{
					post_data.truck_plate_num=this.truck.truck_plate_num_lock
				}

				//发车到货时间
				let now_time=new Date().getTime()
				let start_time=null
				let arrived_time=null
				if(this.form.start_time){//发车时间
					start_time=this.form.start_time.getTime();
					if(start_time>now_time){
						this.$my.other.msg({
							type:"warning",
							str:'发车不能晚于当前时间'
						});
						return;
					}
					post_data.start_time=(start_time/1000).toFixed(0)
				}
				if(this.form.arrived_time){//到货时间
					arrived_time=this.form.arrived_time.getTime();
					if(!this.form.start_time){
						this.$my.other.msg({
							type:"warning",
							str:'有到货时间时必须先有发车时间'
						});
						return;
					}
					if(arrived_time<=start_time){
						this.$my.other.msg({
							type:"warning",
							str:'到货时间不能早于发车时间'
						});
						return;
					}
					if(arrived_time>now_time){
						this.$my.other.msg({
							type:"warning",
							str:'到货时间不能晚于当前时间'
						});
						return;
					}
					post_data.arrived_time=(arrived_time/1000).toFixed(0)
				}

				//运费 必填
				if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){
					post_data.freight_total=this.form.freight_total
				}

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//添加成功
						this.$my.other.confirm({
							content:"发货成功,是否前往运单列表?",
							confirm:()=>{

								this.$router.push({
									path:'/pages/transit_real_time/tord_act_list'
								});
							}
						});
					}
				});
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//清空货源数据
						this.form.out_trade_num=''
						this.form.group1_num=''
						this.form.mark=''
						this.shipper_bl.rel=null
						this.shipper_bl.cargo_name=''

						this.shipper_bl.case_addr={
							prov:'',
							city:'',
							county:'',
							addr:'',
							total_text:''
						}

						this.shipper_bl.aim_addr={
							prov:'',
							city:'',
							county:'',
							addr:'',
							total_text:''
						}

						this.cargo_weight.num=''
						this.cargo_weight.is_need_weight=''
						this.cargo_weight.is_need_weight_text=''

						this.cargo_volume.num=''
						this.cargo_volume.is_need_volume=''
						this.cargo_volume.is_need_volume_text=''

						this.driver.tel=''
						this.driver.name=''
						this.driver.user_num=''

						this.truck.truck_plate_num=''
						this.truck.truck_info_str=''
					}
				});
			},

			//查找货车
			truck_ser(){
				
				//格式判断
				if(!this.$my.check.is_plate_num(this.truck.truck_plate_num)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少车牌号或者格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_api',
						truck_plate_num:this.truck.truck_plate_num,
					},
					callback:(data)=>{
						
						let truck_info=data.list[0];

						if(!truck_info){
							this.$my.other.msg({
								type:"warning",
								str:'未搜索到此货车, 请先添加'
							});
							return;
						}

						if(truck_info.status!='2'){
							this.$my.other.msg({
								type:"warning",
								str:'此货车尚未审核通过, 请联系客服'
							});
							return;
						}

						this.truck.truck_plate_num_lock=truck_info.truck_plate_num
						this.truck.truck_info_str=truck_info.truck_type_name+' / '+truck_info.truck_type_name2
					}
				});
			},

			//清空货车信息
			truck_clear(){
				this.truck.truck_plate_num=''
				this.truck.truck_info_str=''
			},

			//查找司机
			driver_ser(){

				//必须先选择路线
				if(!this.shipper_bl.rel){
					this.$my.other.msg({
						type:"warning",
						str:'必须先选择路线'
					});
					return;
				}
				
				//格式判断
				if(!this.$my.check.is_tel(this.driver.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少司机电话号码或者格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_info_by_outlets',
						user_tel:this.driver.tel,
					},
					callback:(data)=>{

						if(data.status!='2'){
							this.$my.other.msg({
								type:"warning",
								str:'此司机尚未审核通过, 请联系客服'
							});
							return;
						}

						if(data.user_num==this.shipper_bl.rel[0]){
							this.$my.other.msg({
								type:"warning",
								str:'司机与货主不能是同一人'
							});
							return;
						}

						this.driver.user_num=data.user_num
						this.driver.name=data.user_name
					}
				});
			},

			//清空司机信息
			driver_clear(){
				this.driver.tel=''
				this.driver.name=''
				this.driver.user_num=''
			},

			//客户线路选择
			shipper_bl_change(){

				//取出线路详情
				let bl_info=this.shipper_bl.original_data[this.shipper_bl.rel[1]]
				
				//写入货物名称
				this.shipper_bl.cargo_name=bl_info.cargo_name

				//写入发货地
				this.shipper_bl.case_addr.prov=bl_info.case_prov
				this.shipper_bl.case_addr.city=bl_info.case_city
				this.shipper_bl.case_addr.county=bl_info.case_county
				this.shipper_bl.case_addr.addr=bl_info.case_addr
				this.shipper_bl.case_addr.lat=bl_info.case_lat
				this.shipper_bl.case_addr.lng=bl_info.case_lng
				this.shipper_bl.case_addr.total_text=bl_info.case_prov+' / '+bl_info.case_city+' / '+bl_info.case_county+"  / "+bl_info.case_addr
			
				//写入到货地
				this.shipper_bl.aim_addr.prov=bl_info.aim_prov
				this.shipper_bl.aim_addr.city=bl_info.aim_city
				this.shipper_bl.aim_addr.county=bl_info.aim_county
				this.shipper_bl.aim_addr.addr=bl_info.aim_addr
				this.shipper_bl.aim_addr.lat=bl_info.aim_lat
				this.shipper_bl.aim_addr.lng=bl_info.aim_lng
				this.shipper_bl.aim_addr.total_text=bl_info.aim_prov+' / '+bl_info.aim_city+' / '+bl_info.aim_county+"   / "+bl_info.aim_addr

				//结算方式
				this.shipper_bl.settlement_type=bl_info.settlement_type

				//是否是双边
				this.shipper_bl.is_need_return=bl_info.is_need_return

				//运输距离
				this.shipper_bl.distance=bl_info.distance

				//是否必填重量
				this.cargo_weight.is_need_weight=bl_info.is_need_weight
				switch(bl_info.is_need_weight){
					case '1':this.cargo_weight.is_need_weight_text="必填, 请输入";break;
					case '2':this.cargo_weight.is_need_weight_text="非必填";break;
				}

				//是否必填体积
				this.cargo_volume.is_need_volume=bl_info.is_need_volume
				switch(bl_info.is_need_volume){
					case '1':this.cargo_volume.is_need_volume_text="必填, 请输入";break;
					case '2':this.cargo_volume.is_need_volume_text="非必填";break;
				}
			},
			
			//获取货主线路
			get_shipper_bl(){

				//去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_business_line',
						ctr:'shipper_bl_list_admin'
					},
					callback:(data)=>{
						
						//格式化车型数据
						let shipper_list={};
						let original_data={}
						
						//遍历
						for(var item of data.list){

							//原始数据
							original_data[item.id]={...item}

							//汇集货主数据
							if(!shipper_list[item.shipper_num]){
								shipper_list[item.shipper_num]={
									value:item.shipper_num,
									label:item.shipper_company_name,
									children:[]
								};
							}

							//结算方式
							var settlement_type_text=""
							switch(item.is_need_return){
								case '1':settlement_type_text="按吨数结算";break;
								case '2':settlement_type_text="按方数结算";break;
								case '3':settlement_type_text="按趟结算";break;
							}

							//是否是双边
							var is_need_return_text=""
							switch(item.is_need_return){
								case '1':is_need_return_text="双边";break;
								case '2':is_need_return_text="单边";break;
							}

							//汇集货主路线数据
							shipper_list[item.shipper_num].children.push({
								value:item.id,
								label:item.case_city+'/'+item.case_county+' ---- '+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')',
							})
						}

						//置入货主数据
						this.shipper_bl.data=Object.values(shipper_list)//联动主数据
						this.shipper_bl.original_data=original_data//原始数据
					}
				})
			}
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.page{
		background-color: #fff;
		padding:0 20px 20px 20px;
		border-radius: 10px;
		height: calc(100% - 20px);
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>